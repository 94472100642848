import { useState, useContext } from "react";
import {useNavigate} from 'react-router-dom';
import StoreContext from "../context/store";

 const url = 'https://api.artemzimin.ru/public/api/login';

 const Login = ()=>{

    //let navigate = useNavigate();

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [errorCredentialss, setErrorCredentialss] = useState([]);

    const {setIsAuth} = useContext(StoreContext);

    const Auth = async (event)=> {
        event.preventDefault();
        
        try {
            const loginRequest = {
                method: "POST",
                headers: {"Content-Type":"application/json"},
                body: JSON.stringify({login, password})
            }

            const fetchData = await fetch(url, loginRequest);
            const jsonData = await fetchData.json();    
            
            if(jsonData.error) {
                setErrorCredentialss(jsonData.error.errors?.login ?? '');
            }else{
                localStorage.setItem('token', jsonData.data.token);
                setIsAuth(true);
            }


        } catch (error) {
            console.log(error)
        }
    }

    return (
        <section className="bg-gray-50 bg-gray-900">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
                <div className="w-full bg-white rounded-lg shadow border md:mt-0 sm:max-w-md xl:p-0 bg-gray-800 border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
                        <form onSubmit={Auth} className="space-y-4 md:space-y-6">
                            <div>
                                <label htmlFor="login" className="block mb-2 text-sm font-medium text-gray-900 text-white">Логин</label>
                                <input onChange={({target})=>setLogin(target.value)} type="text" name="text" id="login" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Логин" required="" />
                            </div>
                            <div>
                                <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 text-white">Пароль</label>
                                <input onChange={({target})=>setPassword(target.value)} type="password" name="password" id="password" placeholder="••••••••" className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="" />
                            </div>
                            <div>
                                {errorCredentialss.length > 0 && (
                                    <>
                                        <p className="text-red-600">Вы ввели не верные данные</p>
                                    </>
                                )}
                            </div>
                            <button type="submit" className="bg-blue-500 w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Войти</button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login;