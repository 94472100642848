import React, { useState, useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {FaRubleSign} from "react-icons/fa";

function Xbet(){
    
    let s_date = new Date();
    let currentStartDate = s_date.setDate(s_date.getDate() - 1);

    const [startDate, setStartDate] = useState(new Date(currentStartDate));
    const [endDate, setEndDate] = useState(new Date());
    const [statData, setStatData] = useState([])
    const [income, setIncome] = useState(0);
    const [today, setToday] = useState(false);

    useEffect(()=>{
        fetchBet();
    }, [])

    function dateToYMD(date) {
        var d = date.getDate();
        var m = date.getMonth() + 1; //Month from 0 to 11
        var y = date.getFullYear();
        return '' + y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
    }

    
    const fetchBet = async ()=>{
        const url = `https://api.artemzimin.ru/public/api/1xbet/${dateToYMD(startDate)}/${dateToYMD(endDate)}`;

        dateToYMD(startDate) === dateToYMD(endDate) ? setToday(true) : setToday(false);

        const token = localStorage.getItem('token');
        const requestData = {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        }

        try {
            const fetchData = await fetch(url, requestData)
            const ResponseData = await fetchData.json();
            setStatData(ResponseData.data[0]);

            if(dateToYMD(startDate) == dateToYMD(endDate)){
                setIncome((ResponseData.data[0].SumProf)*0.15);
            }else{
                setIncome(null)
            }
        } catch (error) {
            console.log(error)
        }
    }
    


    return (
        <div>
        <div className="lg:flex lg:mb-0 mb-10 gap-10">
        <ReactDatePicker
                    className="border p-2 rounded shadow-lg w-full mb-10"
                    dateFormat="Y-MM-dd" 
                    selected={startDate} 
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(date) => setStartDate(date)} />

        <ReactDatePicker
                    className="border p-2 rounded shadow-lg w-full"
                    dateFormat="Y-MM-dd" 
                    selected={endDate} 
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    onChange={(date) => setEndDate(date)} />
        </div>
        <button className="shadow-lg w-full text-white bg-rose-600 p-2 rounded-md" onClick={fetchBet}>Получить статистику</button>

            <ul className="border rounded-lg mt-10 bg-white shadow-lg">
                <li className="p-4">Количество регистраций: {Math.round(statData?.Regs)}</li>
                <li className="p-4 flex items-center">Сумма депозитов: <span className={(statData?.SumDep > 0 ? "text-green-500":"text-red-500") + " flex items-center"}>{Math.round(statData?.SumDep)} <FaRubleSign /></span></li>
                <li className="p-4 flex items-center">
                    Игроки проиграли: {Math.round(statData?.SumProf)} <FaRubleSign />
                </li>
                {today ? <li className="p-4 flex">Потенциальный доход: <span className={(income > 0) ? "text-green-500 flex items-center" : "text-red-500 flex items-center"}>{(income > 0) ? " (+"+Math.round(income)+")" : "("+Math.round(income)+")"} <FaRubleSign /> </span></li> : ''}
                <li className="p-4 flex items-center">Сумма комиссий: <span className={((statData?.ItogComission > 0) ? "text-green-500" : "text-red-500")+" flex items-center"} >{Math.round(statData?.ItogComission)} <FaRubleSign /> </span> </li>
            </ul>

        </div>
    )
}

export default Xbet;

