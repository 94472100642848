import axios from "axios";
import React, { useEffect, useState } from "react";
import {FaRubleSign} from "react-icons/fa";

function YandexAds(){
    const url = 'https://api.artemzimin.ru/public/api/rsya';
    const [statYesterday, setStatyesterday] = useState([]);
    const [statToday, setStattoday] = useState([]);

    const getStat = async ()=>{
        const token = localStorage.getItem('token');
        
        const requestData = {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        }

        try {
            const dataFetch = await fetch(url, requestData);
            const dataJson = await dataFetch.json();
            setStatyesterday(dataJson[0].yesterday_data.hashref.item[0].hashref.item[8].hashref.item.arrayref.item.hashref.item);
            setStattoday(dataJson[1].today_data.hashref.item[0].hashref.item[8].hashref.item.arrayref.item.hashref.item);
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(()=>{
        getStat();
    }, [])

    return (
        <div>
            <h2 className="text-2xl flex gap-2">Доход вчера: <span className="text-green-500 flex items-center">{statYesterday} <FaRubleSign /></span></h2>
            <h2 className="text-3xl flex gap-2">Доход сегодня: <span className="text-green-500 flex items-center">{statToday} <FaRubleSign /></span></h2>
        </div>
    )
}

export default YandexAds;