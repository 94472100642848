import Admitad from './Admitad';
import Xbet from './Xbet';
import YandexAds from './YandexAds';
import YandexMetrika from './YandexMetrika';
import Octoclick from './Octoclick';
import Timeweb from './Timeweb';
import Check from './Check';

import XbetLogo from '../images/logo_1xbet.svg';
import AdmitadLogo from '../images/admitad.svg';
import YandexMetrikaLogo from '../images/yandexMetrika.svg';
import YandexAdsLogo from '../images/YandexAds.svg';
import OctoclickLogo from '../images/octoclick.svg';


const Stat = ()=>{
    return <div className="container lg:mx-auto px-4 pt-10">
    <div className="lg:flex lg:columns-3 gap-10">
      <div className='admitad rounded-lg lg:p-12 p-8 shadow-lg w-full mb-12'>
        <div className='bg-white p-8 rounded mb-10'>
          <img className='w-48' src={YandexAdsLogo} />
          <YandexAds/>
        </div>
        <div className='bg-white p-8 rounded mb-10'>
          <img className='w-32' src={OctoclickLogo} />
          <Octoclick/>
        </div>
        <div className='xbet rounded-lg w-full lg:p-12 p-8 shadow-lg'>
          <div className='w-48 mb-10'>
            <img src={XbetLogo} />
          </div>
          <Xbet />
        </div>
        <div className='mt-10 yandexmetrika rounded-lg lg:p-12 p-8 shadow-lg w-full mb-12 bg-white'>
        <div className='mb-10'>
            <img src={YandexMetrikaLogo} />
          </div>
          <YandexMetrika />
        </div>
        <div className='mt-10'>
          <Check />
        </div>
      </div>
    </div>
  </div>
}

export default Stat;