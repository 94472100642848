import React, { useEffect, useState } from "react";
import axios from "axios";

const urlMetrika = 'https://api.artemzimin.ru/public/api/metrika';


const YandexMetrika = () => {

    const [counterChelny, setChenly] = useState([]);
    const [counterObmen, setObmen] = useState([]);
    const [counterIncest, setIncest] = useState([]);
    const [counterTales, setTales] = useState([]);
    const [counterStory, setStrory] = useState([]);
    const [counterKbt, setKbt] = useState([]);
    const [counterSteam, setSteam] = useState([]);

    const getChelny = async ()=>{
        
        const token = localStorage.getItem('token');
        const requestData = {
            method: 'GET',
            headers: {"Authorization": `Bearer ${token}`}
        }

        try {
            const counterData = await axios.get(urlMetrika, requestData);
            setChenly(counterData.data[1].chelny_data.totals);
            setObmen(counterData.data[0].toys_data.totals);
            setIncest(counterData.data[2].incest_data.totals);
            setKbt(counterData.data[3].kbt_data.totals);
            setTales(counterData.data[4].sextales_data.totals);
            setStrory(counterData.data[5].sexxstrory_data.totals);
            setSteam(counterData.data[6].smenaregionasteam_data.totals);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        getChelny() 
    },[])

    return (
        <ul className="border rounded-lg bg-white shadow-lg">
            <li className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-200 flex items-center">Smenaregionasteam.ru: <span className="text-bold text-xl mx-2 bg-green-500 text-white px-2 rounded">{counterSteam}</span></li>
            <li className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-200 flex items-center">Chelny.ru: <span className="text-bold text-xl mx-2 bg-green-500 text-white px-2 rounded">{counterChelny}</span></li>
            <li className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-200 flex items-center">Raspmoskbt.ru: <span className="text-bold text-xl mx-2 bg-green-500 text-white px-2 rounded">{counterKbt}</span></li>
            <li className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-200 flex items-center">Obmentoys.ru: <span className="text-bold text-xl mx-2 bg-green-500 text-white px-2 rounded">{counterObmen}</span></li>
            
            <li className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-200 flex items-center">Sextales.net: <span className="text-bold text-xl mx-2 bg-green-500 text-white px-2 rounded">{counterTales}</span></li>
            <li className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-200 flex items-center">Sexxxstrory.ru: <span className="text-bold text-xl mx-2 bg-green-500 text-white px-2 rounded">{counterStory}</span></li>
            <li className="py-2 px-4 w-full border-b border-gray-200 dark:border-gray-200 flex items-center">Inceststory.ru: <span className="text-bold text-xl mx-2 bg-green-500 text-white px-2 rounded">{counterIncest}</span></li>
        </ul>
    )
}

export default YandexMetrika;