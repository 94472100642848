import { useEffect, useState } from "react"
import axios from "axios"

const Check = () => {

    const [sites, setSites] = useState([]);

    const sitesData = [];

    const checkSite = async () => {
        const fetchData = await fetch('https://api.artemzimin.ru/public/api/check');
        const response = await fetchData.json()
        setSites(response)
    }

    useEffect(()=>{
        checkSite();
    }, [])

    return (
        <ul className="bg-white shadow-lg rounded p-4">
        {
            sites.map(item=>
                <li className="item-center font-bold my-2">{item.url} {item.status === 200 ? <span className="p-2 bg-green-400 rounded-full inline-block border border-black"></span> : <span className="p-2 bg-red-400 rounded-full inline-block border border-black"></span>} </li>
            )
        }
        </ul>
    )

}

export default Check;