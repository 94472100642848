import './App.css';

import {Routes, Route, Link} from 'react-router-dom';
import { useEffect, useState } from 'react';
import StoreContext from './context/store';
import Login from './components/Login';
import Stat from './components/Stat';
import SoldCase from './components/SoldCase/SoldCase';
import axios from 'axios';
import { sha256 } from 'js-sha256';

function App() {

  const [isAuth, setIsAuth] = useState(false);


  useEffect(()=>{
    if(localStorage.getItem('token') !== null){
      setIsAuth(true)
    }else{
      setIsAuth(false)
    }
  }, [])

  const logOutHander = () => {
    localStorage.removeItem('token');
    setIsAuth(false)
  }

  return (
    <StoreContext.Provider value={{setIsAuth}}>
      {!isAuth ?
      <>
        <Login />
      </>
      :
      <>
      <nav className="bg-white border-gray-200 dark:bg-gray-900">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
          <div className="flex items-center">
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">WebmasterStatistics</span>
          </div>
          <button data-collapse-toggle="navbar-default" type="button" className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="false">
            <span className="sr-only">Open main menu</span>
            <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
          </button>
          <div className="hidden w-full md:block md:w-auto" id="navbar-default">
            <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              {/* <li>
                <Link className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page" to="/">Статистика</Link>
              </li>
              <li>
                <Link className="block py-2 pl-3 pr-4 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 dark:text-white md:dark:text-blue-500" aria-current="page" to="/soldcase">Продажи</Link>
              </li> */}
              <li>
              <a onClick={logOutHander} className="block py-2 pl-3 pr-4 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent" href="#">Выход</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className='w-full h-screen'>
        <Routes>
          <Route path='/' element={<Stat />} />
          {/* <Route path='/soldcase' element={<SoldCase />} /> */}
        </Routes>
      </div>
      </>
      }
    </StoreContext.Provider>
  );
}

export default App;
