import { useEffect, useState } from "react";
import { FaDollarSign } from "react-icons/fa";

const Octoclick = () => {
    const url = 'https://api.artemzimin.ru/public/api/octoclick';
    const [octoclick, setOctoclick] = useState(0);
    const getBalance = async()=>{
        try {
            const data = await fetch(url);
            const response = await data.json();
            setOctoclick(response.data.balance_total)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        getBalance();
    }, [])

    return (
        <div className="flex items-center">
            Баланс: {octoclick} <FaDollarSign />
        </div>
    );
}

export default Octoclick;